.media {
    position: relative;

    &::before {
        content: "";
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: calc(var(--media-height, 1) / var(--media-width, 1) * 100%);
    }

    &::after {
        /* to clear float */
        content: "";
        display: table;
        clear: both;
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
    }

    &--image {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}