.media {
    $this: &;

    &--video {
        .video-controls {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: opacity 1s ease-out;
            
            &--playing {
                opacity: 0;
            }

            button {
                background: none;
                color: inherit;
                border: none;
                padding: 1rem;
                font: inherit;
                cursor: pointer;
                outline: inherit;

                color: #FFF;
            }

            i {
                font-size: 3rem;
            }
        }

        &:hover,
        &:active {
            .video-controls {
                opacity: 1;
            }
        }
    }
}