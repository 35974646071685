.layout {
    &--main {
        $sidebar-width: 12rem;
        $header-height: 7rem;
        $bg-color: #F0F5F9;

        .sidebar {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1;
            width: $sidebar-width;
            height: 100%;
            background: #FFF;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10);

            display: flex;
            overflow: hidden;
            flex-direction: column;
        }

        .header {
            position: fixed;
            top: 0;
            left: 0;
            margin-left: $sidebar-width;
            height: $header-height;
            width: calc(100% - $sidebar-width);
            background-color: $bg-color;
            z-index: 100;
        }

        .main-content {
            margin-left: $sidebar-width;
            margin-top: $header-height;
            background-color: $bg-color;
            min-height: calc(100vh - $header-height);
        }
    }
}