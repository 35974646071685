.exercise {
    $this: &;
    position: relative;

    border: solid 1px #DDD;
    border-radius: 4px;

    &__media {
        position: relative;

        #{$this}__title {
            position: absolute;
            bottom: -10px;
            left: 0;
            width: 100%;
            padding: 32px;
            font-size: 24px;
            line-height: 1;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            color: #fff;

            background: rgb(0, 0, 0);
            background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
        }

        #{$this}__description {
            position: absolute;
            bottom: -10px;
            right: 0;
            padding: 4px;
            font-size: 24px;
            line-height: 1;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
            cursor: pointer;
        }

        #{$this}__days {
            position: absolute;
            bottom: -10px;
            left: 0;
            padding: 4px;

            display: inline-flex;
            flex-wrap: wrap;

            &-item {
                color: #fff;
                border: solid 2px #fff;
                padding: 2px 6px;
                border-radius: 4px;
                font-size: 12px;
                line-height: 1;
                margin-right: 2px;

            }
        }
    }

    &__info {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 1;

        display: flex;

        &-box {
            flex: 1 0 33%;
            padding: 12px;
        }

        .info-box {
            text-align: center;
            border: solid 1px #000;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.9);
            padding: 8px;
            color: #fff;

            &__number {
                font-size: 32px;
                line-height: 1;
                font-weight: bold;
                margin-bottom: 4px;
                position: relative;

                &--seconds::after {
                    content: '"';
                    position: absolute;
                    right: 4px;
                }
            }

            &__description {
                font-size: 12px;
                text-transform: uppercase;
            }
        }
    }

    &__body {
        padding: 16px 8px;

        &-note {
            .note__header {
                font-size: 20px;
                font-weight: bold;
            }

            .note__description {
                border-bottom: solid 1px #222;
            }
        }
    }
}

.sets-progress {
    $this: &;
    
    margin-top: 16px;

    &__header {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    }

    &__items-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0 32px;

        #{$this}__item {
            padding: 6px;
        }
    }
}