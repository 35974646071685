$font-family-base: 'Poppins', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$primary: #FC6011;
$body-color: #11263C;

// BTN
$btn-color: #fff;
$btn-border-radius: 0.3125rem;
$btn-border-radius-sm: 0.3125rem;
$btn-border-radius-lg: 0.3125rem;

// LINK
$link-color: #11263C;
$link-hover-color: $primary;

// NAV
$nav-tabs-link-active-color: #FC6011;

// TABLE
$table-cell-padding-y: 1rem;
$table-cell-padding-x: 1rem;