.nav-link {
    --bs-nav-link-padding-y: 1rem;
    --bs-nav-link-padding-x: 2rem;

    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    
    display: flex;
    align-items: center;

    &:hover,
    &:focus,
    &.active {
        color: $primary;
        background-color: #FFF2EC;
    }
}