.hu-checkbox {
    --form-background: #FFF;
    --form-checked-background: green;
    --form-control-color: #FFF;
    --form-control-dimension: 30px;

    input[type="checkbox"] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        /* Remove most all native input styles */
        appearance: none;
        /* For iOS < 15 */
        background-color: var(--form-background);
        /* Not removed via appearance */
        margin: 0;

        font: inherit;
        color: currentColor;
        width: var(--form-control-dimension);
        height: var(--form-control-dimension);
        border: 2px solid currentColor;
        border-radius: 50%;
        //transform: translateY(-0.075em);
        cursor: pointer;

        display: grid;
        place-content: center;
    }

    input[type="checkbox"]::before {
        content: "";
        width: calc(var(--form-control-dimension) - var(--form-control-dimension)*0.5);
        height: calc(var(--form-control-dimension) - var(--form-control-dimension)*0.5);
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 3em 3em var(--form-control-color);
        /* Windows High Contrast Mode */
        background-color: CanvasText;
    }

    input[type="checkbox"]:checked {
        background-color: var(--form-checked-background);
    }

    input[type="checkbox"]:checked::before {
        transform: scale(1);
    }

    input[type="checkbox"]:focus {
        outline: max(2px, 0.15em) solid currentColor;
        outline-offset: max(2px, 0.15em);
    }

    input[type="checkbox"]:disabled {
        --form-control-color: var(--form-control-disabled);

        color: var(--form-control-disabled);
        cursor: not-allowed;
    }
}